<template>
  <div class="fix-grid d-grid">
    <Navbar />
    <router-view class="content" />
    <CustomFooter />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar';
import CustomFooter from '@/components/Footer';

export default {
  components: {
    Navbar,
    CustomFooter,
  },
};
</script>
<style lang="scss" scoped>
.fix-grid {
  grid-template-rows: auto 1fr auto;
}
</style>
