<template>
  <div class="footer w-100 h-auto px-4 py-2 py-md-2">
    <div
      class="h-100 d-flex w-100 h-100 d-flex flex-row align-items-center justify-content-center justify-content-md-end flex-wrap"
    >
      <a id="codata" href="https://codata.pb.gov.br" target="_blank">
        <b-img-lazy
          class="codata my-2 my-md-0"
          alt="Logomarca da Codata"
          fluid
          :src="require('@/assets/images/logo_codata.png')"
        />
      </a>
      <a id="government" href="https://paraiba.pb.gov.br" target="_blank">
        <b-img-lazy
          class="gov pl-md-3"
          alt="Logomarca do Governo da Paraíba"
          fluid
          :src="require('@/assets/images/logo_branca_2.png')"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomFooter',
};
</script>