<template>
  <b-modal
    id="user-profile"
    size="md"
    centered
    hide-footer
    hide-header
    body-class="p-0"
  >
    <div
      class="our-team pt-3 pt-4 bg-white text-center overflow-hidden position-relative"
    >
      <div class="picture d-inline-block position-relative mb-5">
        <b-img-lazy
          class="image rounded-circle w-100 h-auto"
          alt="Imagem do profile do usuário"
          fluid
          :src="require('@/assets/images/cover-default.jpeg')"
        />
      </div>
      <div class="team-content mb-5">
        <h3 class="name">{{ userName }}</h3>
        <h4 class="title d-block" v-if="papelUsuario === 'ROLE_DOCAS'">
          Usuário DOCAS
        </h4>
        <h4 class="title d-block" v-if="papelUsuario === 'ROLE_RECEITA'">
          Usuário Receita Federal
        </h4>
      </div>
      <h6 class="social position-absolute w-100 text-white m-0 px-0 py-2">
        {{ userEmail }}
      </h6>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import UsuarioHelper from "@/helpers/usuarioHelper";
import { UsuarioService } from "@/services";

export default {
  data() {
    return {
      papelUsuario: "",
    };
  },
  computed: {
    ...mapGetters("oidcStore", ["oidcUser"]),
    userName() {
      return this.oidcUser ? this.oidcUser.name : "";
    },
    userEmail() {
      return this.oidcUser ? this.oidcUser.email : "";
    },
  },
  created() {
    UsuarioService.getCurrentUser().then((resposta) => {
      this.papelUsuario = UsuarioHelper.getPapelUsuario(resposta.data);
    });
  },
};
</script>

<style lang="scss" scoped>
.our-team .picture {
  height: 130px;
  width: 130px;
  z-index: 1;
  position: inherit;
}

.our-team .picture::before {
  content: "";
  width: 100%;
  height: 0;
  border-radius: 50%;
  background-color: $bahama-blue;
  position: absolute;
  bottom: 135%;
  transform: scale(3);
  transition: all 0.3s linear 0s;
}

.our-team:hover .picture::before {
  height: 100%;
}

.our-team .picture::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: $bahama-blue;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.our-team .picture .image {
  transform: scale(1);
  transition: all 0.9s ease 0s;
}

.our-team:hover .picture .image {
  box-shadow: 0 0 0 14px white;
  transform: scale(0.7);
}

.our-team .title {
  font-size: 15px;
  color: #4e5052;
}

.our-team {
  .social {
    background-color: $bahama-blue;
    bottom: -100px;
  }
  .social,
  .team-content,
  .picture {
    transition: all 0.5s ease 0s;
  }
}

.our-team:hover {
  .social {
    bottom: 0;
  }
  .picture {
    margin-bottom: 0 !important;
  }
  .team-content {
    margin-top: 1.35rem;
    margin-bottom: 74px !important;
  }
}
</style>
