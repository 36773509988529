<template>
  <b-navbar toggleable="lg" class="custom-navbar">
    <b-navbar-brand>
      <b-img-lazy
        alt="COP - Controle de Operações Portuárias"
        height="40"
        :src="require('@/assets/images/cop.png')"
      />
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item id="to-home" :to="{ name: 'DashboardPadrao' }">{{
          $t("navbar.home")
        }}</b-nav-item>
        <b-nav-item-dropdown text="Cadastros" right>
          <b-dropdown-item :to="{ name: 'EmpresaPesquisar' }"
            >Empresas</b-dropdown-item
          >
          <b-dropdown-item :to="{ name: 'ArmazenamentoPesquisar' }"
            >Locais de Armazenamento</b-dropdown-item
          >
          <b-dropdown-item :to="{ name: 'OperadorPortuarioPesquisar' }"
            >Operadores Portuários</b-dropdown-item
          >
          <b-dropdown-item :to="{ name: 'MercadoriasPesquisar' }"
            >Mercadorias</b-dropdown-item
          >
          <b-dropdown-item :to="{ name: 'TeusPesquisar' }"
            >Teus</b-dropdown-item
          >
        </b-nav-item-dropdown>
        <b-nav-item-dropdown text="Movimentações" right>
          <b-dropdown-item :to="{ name: 'MovimentacaoPesquisar' }"
            >Pesquisa</b-dropdown-item
          >
          <b-dropdown-item :to="{ name: 'MovimentacaoRelatorio' }"
            >Relatórios</b-dropdown-item
          >
        </b-nav-item-dropdown>
        <b-nav-item-dropdown text="Operações Portuárias" right>
          <b-dropdown-item :to="{ name: 'PesquisarOperacaoPortuaria' }"
            >Pesquisa</b-dropdown-item
          >
          <b-dropdown-item :to="{ name: 'RelatorioOperacaoPortuaria' }"
            >Relatórios</b-dropdown-item
          >
        </b-nav-item-dropdown>
        <b-nav-item-dropdown text="Operações Portuárias de Mercadoria" right>
          <b-dropdown-item
            :to="{ name: 'PesquisarOperacaoPortuariaMercadoria' }"
            >Pesquisa</b-dropdown-item
          >
          <b-dropdown-item
            :to="{ name: 'RelatorioOperacaoPortuariaMercadoria' }"
            >Relatórios</b-dropdown-item
          >
        </b-nav-item-dropdown>

        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown
            v-if="oidcUser"
            id="user-dropdown"
            no-caret
            right
          >
            <template v-slot:button-content>
              <img
                :src="require('@/assets/images/cover-default.jpeg')"
                class="rounded-circle"
                height="35"
                width="35"
              />
            </template>
            <b-dropdown-text class="font-weight-bold">{{
              oidcUser.email
            }}</b-dropdown-text>
            <b-dropdown-divider />
            <b-dropdown-item id="profile" v-b-modal.user-profile>{{
              $t("navbar.user.profile")
            }}</b-dropdown-item>
            <b-dropdown-item id="logout" @click="logout">{{
              $t("navbar.user.logout")
            }}</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-navbar-nav>
    </b-collapse>

    <b-navbar-brand>
      <b-img-lazy
        alt="Porto de Cabedelo - Companhia Docas da Paraíba"
        height="40"
        :src="require('@/assets/images/logo_porto.png')"
      />
    </b-navbar-brand>
    <UserProfile />
  </b-navbar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserProfile from "./UserProfile";
import UsuarioHelper from "@/helpers/usuarioHelper";
import { UsuarioService } from "@/services";

export default {
  data() {
    return {
      papelUsuario: "",
    };
  },
  components: { UserProfile },
  computed: {
    ...mapGetters("oidcStore", ["oidcUser"]),
  },
  methods: {
    ...mapActions("oidcStore", ["signOutOidc"]),
    logout() {
      this.signOutOidc()
        .then(() => {
          this.$router.push({ name: "oidcCallback" });
        })
        .catch((err) => console.log(err));
    },
  },
  created() {
    UsuarioService.getCurrentUser().then((resposta) => {
      this.papelUsuario = UsuarioHelper.getPapelUsuario(resposta.data);
    });
  },
};
</script>
